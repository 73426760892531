<template>
<div>
    <div class="tripdatedialog-container">
        <h2>{{ $t('trips.selectDate') }}</h2>

        <vs-input v-model="date" type="date" style="margin-top: 20px;" block :min="new Date().toISOString().split('T')[0]" />
        <div style="display: flex; width: 100%; justify-content: space-between; gap: 10px;">
            <vs-button size="large" border block @click="callback(null, true)">
                {{ $t('common.cancel') }}
            </vs-button>
            <vs-button size="large" block @click="callback(date, true)">
                {{ $t('common.continue') }}
            </vs-button>
        </div>
    </div>

</div>
</template>

<script>
export default {
    name: "tripDateDialog",
    props: {
        callback: Function,
        data: Object
    },
    data(){
        return {
            date: ''
        }
    },
    mounted(){
        if(this.data != null){
            var d = new Date(this.data.end_date);
            if(d.getFullYear() >= 1970){
                this.date = this.data.end_date.split('T')[0];
            }
            
        }
    },
    components: {}
}
</script>

<style scoped>
p,
h3,h2 {
    margin: 0;
}

.tripdatedialog-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-top: 40px;
    gap: 10px;
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
}
</style><style>
.tripdatedialog-container input {
    width: 100% !important;
    -webkit-appearance: none;
  -moz-appearance: none;
  text-align: left!important;
  justify-content: flex-start;
}

.tripdatedialog-container input::-webkit-date-and-time-value {
  text-align: left;
}
</style>
