<template>
<l-map ref="map" :style="{height: innerHeight + 'px'}" :zoom="zoom" :center="center" :options="{zoomControl: false}" :minZoom="7" @update:bounds="boundsChanged" @update:zoom="zoomChanged" @ready="mapReady">
    <l-tile-layer :url="url"></l-tile-layer>
    <l-marker :lat-lng="userCoords" v-if="userCoords != null" :options="{zIndexOffset: 601}">
        <l-icon :icon-size="dynamicSizePin" :icon-url="'/osm/pin.png'" />
    </l-marker>
    <v-marker-cluster :options="{spiderfyOnMaxZoom: false, showCoverageOnHover: false, zoomToBoundsOnClick: true, disableClusteringAtZoom: 13}">
        <l-marker :lat-lng="[poi.latitude,poi.longitude]" @click="poiClick(poi)" v-for="poi in pois" :key="'poi-' + poi.id" @mouseover="getPoi(poi)">
            <l-icon :icon-size="dynamicSize" :icon-anchor="dynamicAnchor" :icon-url="baseUri + '/targets/' + poi.target_id + '/picture'" />
            <l-tooltip :options="{direction: 'center',}"> {{ poiDetails == null ? '...' : poiDetails.name }}</l-tooltip>
        </l-marker>

    </v-marker-cluster>

    <l-control-zoom v-if="controlsEnabled" position="bottomright"></l-control-zoom>
</l-map>
</template>

<script>
import {
    LMap,
    LTileLayer,
    LMarker,
    LIcon,
    LControlZoom,
    LTooltip,
} from 'vue2-leaflet';

import L from 'leaflet';
import {
    apiCall,
    baseUri
} from '../utils/ApiMiddleware';
import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster'
import {
    GlobalEventEmitter
} from '../utils/GlobalEventEmitter';
export default {
    name: 'openStreetMap',
    props: {
        controlsEnabled: {
            type: Boolean,
            default: true
        },

        poiClick: {
            type: Function,
            default: function () {}
        },

        pois: {
            type: Array,
            default: function () {
                return [];
            }
        },

        center: {
            type: Array,
            default: function () {
                return [45.9488798, 8.5512232];
            }
        },

        userCoords: {
            type: Array,
            default: function () {
                return null;
            }
        },

        innerHeight: {
            type: Number,
            default: 100,
            required: false
        }
    },
    components: {
        LMap,
        LTileLayer,
        LMarker,
        LIcon,
        LControlZoom,
        LTooltip,
        'v-marker-cluster': Vue2LeafletMarkerCluster
    },

    data() {
        return {
            L,
            url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
            zoom: 15,
            markerLatLng: [45.9488798, 8.5512232],
            poiDetails: null,
            dynamicSizePin: [25, 25],
            newZoom: null,
            baseUri
        }
    },

    methods: {
        boundsChanged(e) {
            this.$emit('boundsChanged', e);
        },

        zoomChanged(e) {
            this.dynamicSizePin = [e * 2, e * 2];
        },

        mapReady() {
            if(document.getElementById('center-user-btn') != null){
                return;
            }
            var el = document.createElement('a');
            el.id = 'center-user-btn';
            el.onclick = function(){
                GlobalEventEmitter.$emit('askForGPS');
            }
            el.className = 'leaflet-control-zoom-in';
            el.style.display = 'flex';
            el.style.justifyContent = 'center';
            el.style.alignItems = 'center';
            var img = document.createElement('img');
            img.src = require('../assets/icons/center.png');
            img.style.width = '24px';
            el.appendChild(img);
            var target = document.querySelector('.leaflet-control-zoom');
            target.insertBefore(el, target.firstChild);
        },

        async getPoi(poi) {
            this.poiDetails = null;
            const response = await apiCall('GET', '/pois/' + poi.id);
            if (response.status == 200) {
                this.poiDetails = response.data;
            } else if (response.status != 0) {
                this.$vs.notification({
                    title: 'Qualcosa è andato storto!',
                    text: 'Impossibile caricare il punto di interesse, riprova più tardi.',
                    color: 'danger',
                    position: 'top-right'
                })
            }
        },

        updateZoom(zoom) {
            this.newZoom = zoom;
        }
    },

    created() {
        GlobalEventEmitter.$on('setZoom', this.updateZoom)
    },
    beforeDestroy() {
        GlobalEventEmitter.$off('setZoom', this.updateZoom)
    },

    watch: {
        zoom(n) {
            console.log(n)
            this.dynamicSizePin = [25 * (n / 10), 25 * (n / 10)];
        },
        center(n) {
            console.log(n)
            this.$refs.map.mapObject.setView(new L.LatLng(n[0], n[1]), this.newZoom != null ? this.newZoom : this.zoom);
            if (this.newZoom != null) {
                this.zoom = this.newZoom;
                this.newZoom = null;
            }

        }
    },

    mounted() {

    },
    computed: {
        dynamicSize() {
            return [40.5, 46.5];
        },
        dynamicAnchor() {
            return [46.5 * 0.5, 0.86 * 40.5];
        },
    },
}
</script>

<style>
@import '~leaflet/dist/leaflet.css';
@import "~leaflet.markercluster/dist/MarkerCluster.css";
@import "~leaflet.markercluster/dist/MarkerCluster.Default.css";
</style><style>
@media (max-width: 600px) {
    .leaflet-bottom {
        bottom: 50px;
    }
}
</style>
